var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆自编号", prop: "vehicleNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆自编号" },
                model: {
                  value: _vm.form.vehicleNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleNo", $$v)
                  },
                  expression: "form.vehicleNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "租赁单位名称", prop: "leaseCompany" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入租赁单位名称 租赁单位" },
                model: {
                  value: _vm.form.leaseCompany,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "leaseCompany", $$v)
                  },
                  expression: "form.leaseCompany",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "租赁价格(元/年)", prop: "leasePrice" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入租赁价格(元/年)" },
                model: {
                  value: _vm.form.leasePrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "leasePrice", $$v)
                  },
                  expression: "form.leasePrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "租赁时间", prop: "leaseDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.leaseDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "leaseDate", $$v)
                  },
                  expression: "form.leaseDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "租赁合同上传", prop: "leaseAgreement" } },
            [
              _c("file-upload", {
                attrs: {
                  folder: _vm.paramsLeaseAgreementPhoto.folder,
                  type: "image",
                },
                model: {
                  value: _vm.form.leaseAgreement,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "leaseAgreement", $$v)
                  },
                  expression: "form.leaseAgreement",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆初始行驶里程(km)", prop: "vehicleOdo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆初始行驶里程(km)" },
                model: {
                  value: _vm.form.vehicleOdo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleOdo", $$v)
                  },
                  expression: "form.vehicleOdo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }