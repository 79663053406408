<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <!-- <a-form-model-item label="车辆台账ID" prop="vehicleAccountId">
        <a-input v-model="form.vehicleAccountId" placeholder="请输入车辆台账ID" />
      </a-form-model-item> -->
      <a-form-model-item label="车辆自编号" prop="vehicleNo">
        <a-input v-model="form.vehicleNo" placeholder="请输入车辆自编号" />
      </a-form-model-item>
      <a-form-model-item label="租赁单位名称" prop="leaseCompany">
        <a-input v-model="form.leaseCompany" placeholder="请输入租赁单位名称 租赁单位" />
      </a-form-model-item>
      <a-form-model-item label="租赁价格(元/年)" prop="leasePrice">
        <a-input v-model="form.leasePrice" placeholder="请输入租赁价格(元/年)" />
      </a-form-model-item>
      <a-form-model-item label="租赁时间" prop="leaseDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.leaseDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="租赁合同上传" prop="leaseAgreement">
        <!-- <a-input v-model="form.leaseAgreement" placeholder="请输入租赁合同上传" /> -->
        <file-upload v-model="form.leaseAgreement" :folder="paramsLeaseAgreementPhoto.folder" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="车辆初始行驶里程(km)" prop="vehicleOdo">
        <a-input v-model="form.vehicleOdo" placeholder="请输入车辆初始行驶里程(km)" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLease, addLease, updateLease } from '@/api/iot/lease'
import { parseDateTime } from '@/utils/ruoyi'
export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleAccountId: null,
        vehicleNo: null,
        leaseCompany: null,
        leasePrice: null,
        leaseDate: null,
        leaseAgreement: null,
        vehicleOdo: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleAccountId: [{ required: true, message: '车辆台账ID不能为空', trigger: 'blur' }],
        vehicleNo: [{ required: true, message: '车辆自编号不能为空', trigger: 'blur' }],
        leaseCompany: [{ required: true, message: '租赁单位名称不能为空', trigger: 'blur' }],
        leasePrice: [{ required: true, message: '租赁价格不能为空', trigger: 'blur' }],
        leaseDate: [{ required: true, message: '租赁时间不能为空', trigger: 'change' }]
      },
      leaseAgreementPhoto: null
    }
  },
  filters: {},
  created() {},
  computed: {
    paramsLeaseAgreementPhoto() {
      return {
        fileName: 'leaseAgreement' + parseDateTime(new Date(), 'yyyyMMddHHmmss'),
        type: 'aliyunoss',
        folder: 'vehicleAccount/' + this.form.vehicleNo
      }
    }
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleAccountId: null,
        vehicleNo: null,
        leaseCompany: null,
        leasePrice: null,
        leaseDate: null,
        leaseAgreement: null,
        vehicleOdo: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(curVehicleNo, curVehicleAccountId) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.vehicleNo = curVehicleNo
      this.form.vehicleAccountId = curVehicleAccountId
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLease(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLease(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addLease(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    getfileNameLeaseAgreementPhoto(fileName) {
      this.leaseAgreementPhoto = fileName
    }
  }
}
</script>
