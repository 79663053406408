import request from '@/utils/request'

// 查询车辆租赁列表
export function listLease (query) {
  return request({
    url: '/iot/lease/list',
    method: 'get',
    params: query
  })
}

// 查询车辆租赁详细
export function getLease (id) {
  return request({
    url: '/iot/lease/' + id,
    method: 'get'
  })
}

// 新增车辆租赁
export function addLease (data) {
  return request({
    url: '/iot/lease',
    method: 'post',
    data: data
  })
}

// 修改车辆租赁
export function updateLease (data) {
  return request({
    url: '/iot/lease',
    method: 'put',
    data: data
  })
}

// 删除车辆租赁
export function delLease (id) {
  return request({
    url: '/iot/lease/' + id,
    method: 'delete'
  })
}

// 导出车辆租赁
export function exportLease (query) {
  return request({
    url: '/iot/lease/export',
    method: 'get',
    params: query
  })
}
